<template>
  <div class="calendar-first-team">
    <div class="calendar-ft-header">
      <h1>RESULTADOS</h1>
    </div>
    <div class="select-cont-t">
      <h4>Selecciona el torneo:</h4>
      <div class="cla-select">
        {{ nomtext }} <img src="../assets/grayeq.png" alt="tr" class="eqt" />
        <ul>
          <!-- <li  @click="ligaexp = A24" :value="A24"><a>Apertura 24</a></li> -->
          <li  @click="ligaexp = C24" :value="C24"><a>Clausura 24</a></li>
          <li @click="ligaexp = A23" :value="A23"><a>Apertura 23</a></li>
          <li @click="ligaexp = A23" :value="A23"><a>Apertura 23</a></li>
          <li @click="ligaexp = C23" :value="C23"><a>Clausura 23</a></li>
          <li @click="ligaexp = A22" :value="A22"><a>Apertura 22</a></li>
          <li @click="ligaexp = C22" :value="C22"><a>Clausura 22</a></li>
          <li @click="ligaexp = A21" :value="A21"><a>Apertura 21</a></li>
          <li @click="ligaexp = C21" :value="C21"><a>Clausura 21</a></li>
          <li @click="ligaexp = A20" :value="A20"><a>Apertura 20</a></li>
        </ul>
      </div>
      <!-- <div class="cla-select">
        {{ nomtext }} <img src="../assets/grayeq.png" alt="tr" class="eqt" />
        <ul>
          <li @click="ligaexp = A22" :value="A22"><a>Apertura 22</a></li>
          <li @click="ligaexp = C22" :value="C22"><a>Clausura 22</a></li>
          <li @click="ligaexp = A21" :value="A21"><a>Apertura 21</a></li>
          <li @click="ligaexp = C21" :value="C21"><a>Clausura 21</a></li>
          <li @click="ligaexp = A20" :value="A20"><a>Apertura 20</a></li>
        </ul>
      </div>--->
    </div>

    <div class="matches-month">
      <div class="ma-m-header">
        <!--<h3>Septiembre 2021</h3>-->
      </div>
      <!---PARTIDOS DESDE EL API DE LA LIGA MX--->
      <div v-if="partidosClub.length > 0">
        <div class="match-ma-m pxt" v-for="cal in partidosClub" :key="cal.id">
          <div class="data-match">
            <h4 v-if="cal.fecha == null">Fecha no disponible</h4>
            <h4 v-else>{{ date2(cal.fecha) }}</h4>
            <h6 v-if="cal.horaLocal == '00:00:00'">N/A</h6>
            <h6 v-else>
              {{ horarioFormat(cal.horaLocal) }} hrs <br />
              (hora local)
            </h6>
          </div>
          <div class="logo-journey-cont">
            <img v-if="cal.torneo == 'Clausura'" src="../assets/logoligaexpansion.png" alt="tournament"
              class="tou-logo" />
            <!-- <div class="journey-stadium-cont">
            <img v-if="cal.torneo == 'Clausura' && calendardata.name_tournament[i] == 'Clausura'" 
              src="url+'/get-tournament-image/'+calendardata.image_tournament" alt="tournament" class="tou-logo" />-->
            <div class="journey-stadium-cont">
              <h4>{{ cal.jornada }}</h4>
              <h6>{{ cal.estadio }}</h6>
            </div>
          </div>

          <div class="match-m-cont">
            <div class="team-versus" v-if="cal.clubLocal === 'Cimarrones de Sonora FC'">
              <div class="cim-team" id="teammx">
                <h6>CIMARRONES DE SONORA</h6>
                <img :src="cal.clubLocalLogo" alt="logo" class="logo-cont" />
              </div>
              <div class="result-versus">
                <h4 class="versus" v-if="cal.fecha >= today()">VS.</h4>
                <h4 class="versus" v-else-if="cal.horaLocal == '00:00:00'">
                  VS.
                </h4>
                <h4 class="match-result-vs" id="versa" v-else-if="cal.fecha < today()">
                  <span>{{ cal.golLocal }}</span> -
                  <span>{{ cal.golVisita }}</span>
                </h4>
              </div>
              <div class="cim-team" id="teammx">
                <img :src="cal.clubVisitaLogo" alt="logo" class="logo-cont" />
                <h6>{{ cal.clubVisita }}</h6>
              </div>
            </div>

            <!-- <div class="team-versus" v-else-if="cal.type === 'Visitante'">-->
            <div class="team-versus" v-else>
              <div class="cim-team" id="teammx">
                <h6>{{ cal.clubLocal }}</h6>
                <img :src="cal.clubLocalLogo" alt="logo" class="logo-cont" />
              </div>
              <div class="result-versus">
                <h4 class="versus" v-if="cal.fecha >= today()">VS.</h4>
                <h4 class="versus" v-else-if="cal.horaLocal == '00:00:00'">
                  VS.
                </h4>
                <h4 class="match-result-vs" id="versa" v-else-if="cal.fecha < today()">
                  <span>{{ cal.golLocal }}</span> -
                  <span>{{ cal.golVisita }}</span>
                </h4>
              </div>
              <div class="cim-team" id="teammx">
                <img :src="cal.clubVisitaLogo" alt="logo" class="logo-cont" />
                <h6>CIMARRONES DE SONORA</h6>
              </div>
            </div>

            <div class="button-match-cont">
              <div v-if="cal.fecha >= today()">
                <a v-if="cal.clubLocal === 'Cimarrones de Sonora FC'" :href="boletos(cal.fecha)" target="_blank"
                  rel="noopener noreferrer">
                  <div class="l-button bol">COMPRAR BOLETOS</div>
                </a>
                <a v-else :href="boletos(cal.fecha)" target="_blank" rel="noopener noreferrer" class="nega">
                  <div class="l-button bol nega">COMPRAR BOLETOS</div>
                </a>
              </div>
              <a v-else-if="cal.fecha == null" :href="boletos(cal.fecha)" target="_blank" rel="noopener noreferrer"
                class="nega">
                <div class="l-button bol nega">COMPRAR BOLETOS</div>
              </a>
              <a v-else-if="cal.fecha < today()" :href="'/calendario/' +
                idJornada(cal.fecha, cal.jornada, calendardata)
                " @click="click(torneoSe, divisionSe, temporadaSe, cal.jornada)">
                <div class="l-button">VER RESULTADOS</div>
              </a>
              <a :href="'/calendario/' +
                idJornada(cal.fecha, cal.jornada, calendardata)
                " @click="click(torneoSe, divisionSe, temporadaSe, cal.jornada)" v-else>
                <div class="l-button">VER RESULTADOS</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!---PARTIDOS DESDE EL ADMINISTRADOR--->
      <div v-else-if="partidosClub.length == 0">
        <div class="match-ma-m pxs" v-for="cal in partidosClub1" :key="cal.id">
          <div class="data-match">
            <h4 v-if="cal.descrp == null">Fecha no disponible</h4>
            <h4 v-else>{{ date2(cal.descrp) }}</h4>
            <h6 v-if="cal.hr == '00:00:00'">N/A</h6>
            <h6 v-else>
              {{ horarioFormat(cal.hr) }} hrs <br />
              (hora local)
            </h6>
          </div>
          <div class="logo-journey-cont">
            <img src="../assets/logoligaexpansion.png" alt="tournament" class="tou-logo" />
            <!-- <div class="journey-stadium-cont">
                <img v-if="cal.torneo == 'Clausura' && calendardata.name_tournament[i] == 'Clausura'" 
                  src="url+'/get-tournament-image/'+calendardata.image_tournament" alt="tournament" class="tou-logo" />-->
            <div class="journey-stadium-cont">
              <h4>{{ cal.typegame }}</h4>
              <h6>{{ cal.stadium }}</h6>
            </div>
          </div>

          <div class="match-m-cont liguilla">
            <!-- <div class="team-versus" v-else-if="cal.type === 'Visitante'">-->
            <div class="team-versus">
              <div class="cim-team" id="teammx">
                <h6 v-if="cal.type == 'Local'">CIMARRONES DE SONORA</h6>
                <h6 v-else-if="cal.type == 'Visitante'">{{ cal.opname }}</h6>
                <img v-if="cal.type == 'Local'" src="../assets/Cimarrones-mx.png" alt="logo" class="logo-cont" />
                <img v-else-if="cal.type == 'Visitante'" :src="url + '/get-calendar-image/' + cal.image" alt="logo"
                  class="logo-cont" />
              </div>
              <div class="result-versus">
                <h4 class="versus" v-if="cal.descrp >= today()">VS.</h4>
                <h4 class="versus" v-else-if="cal.hr == '00:00:00'">VS.</h4>
                <h4 class="match-result-vs" id="versa" v-else-if="cal.descrp < today()">
                  <div v-if="cal.type == 'Local'">
                    <span>{{ cal.result_c }}</span> -
                    <span>{{ cal.result_op }}</span>
                  </div>
                  <div v-if="cal.type == 'Visitante'">
                    <span>{{ cal.result_op }}</span> -
                    <span>{{ cal.result_c }}</span>
                  </div>
                </h4>
              </div>
              <div class="cim-team" id="teammx">
                <img v-if="cal.type == 'Local'" :src="url + '/get-calendar-image/' + cal.image" alt="logo"
                  class="logo-cont" />
                <img v-else-if="cal.type == 'Visitante'" src="../assets/Cimarrones-mx.png" alt="logo" class="logo-cont" />
                <h6 v-if="cal.type == 'Visitante'">CIMARRONES DE SONORA</h6>
                <h6 v-else-if="cal.type == 'Local'">{{ cal.opname }}</h6>
              </div>
            </div>

            <div class="button-match-cont">
              <div v-if="cal.descrp >= today()">
                <a v-if="cal.type === 'Local'" href="https://boletomovil.com/cimarrones-sonora" target="_blank"
                  rel="noopener noreferrer">
                  <div class="l-button bol">COMPRAR BOLETOS</div>
                </a>
                <a v-else href="https://boletomovil.com/cimarrones-sonora" target="_blank" rel="noopener noreferrer"
                  class="nega">
                  <div class="l-button bol nega">COMPRAR BOLETOS</div>
                </a>
              </div>
              <a v-else-if="cal.descrp == null" href="https://boletomovil.com/cimarrones-sonora" target="_blank"
                rel="noopener noreferrer" class="nega">
                <div class="l-button bol nega">COMPRAR BOLETOS</div>
              </a>
              <a :href="'/calendario/' +
                idJornada(cal.descrp, cal.typegame, calendardata)
                " @click="click(torneoSe, divisionSe, temporadaSe, cal.typegame)"
                v-else-if="(cal.descrp < today()) & (cal.idJornada < 5)">
                <div class="l-button">VER RESULTADOS</div>
              </a>
              <a :href="'/calendario/' +
                idJornada(cal.descrp, cal.typegame, calendardata)
                " @click="click(torneoSe, divisionSe, temporadaSe, cal.typegame)" v-else>
                <div class="l-button">VER RESULTADOS</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="match-ma-m-mo" v-for="cal in partidosClub" :key="cal.id">
        <div class="match-mo" v-if="cal.clubLocal === 'Cimarrones de Sonora FC'">
          <div class="cim-team">
            <img :src="cal.clubLocalLogo" alt="logo" class="logo-cont" />
            <h6>CIM</h6>
          </div>
          <div class="match-stats">
            <img v-if="cal.torneo == 'Clausura'" src="../assets/logoligaexpansion.png" alt="tournament"
              class="tou-logo" />
            <!-- <div class="journey-stadium-cont">
         <img v-if="cal.torneo == 'Clausura' && calendardata.name_tournament[i] == 'Clausura'" 
          src="../assets/grita-mx.png" alt="tournament" class="tou-logo" />-->
            <div class="data-match">
              <h4 v-if="cal.fecha == null">Fecha no disponible</h4>
              <h4 v-else>{{ date2(cal.fecha) }}</h4>
              <h6 v-if="cal.horaLocal == '00:00:00'">N/A</h6>
              <h6 v-else>
                {{ horarioFormat(cal.horaLocal) }} hrs <br />
                (hora local)
              </h6>
            </div>
            <h4 class="match-result-vs" v-if="cal.fecha < today()">
              <span>{{ cal.golLocal }}</span> - <span>{{ cal.golVisita }}</span>
            </h4>
            <div class="journey-stadium-cont">
              <h4>{{ cal.jornada }}</h4>
              <h6>{{ cal.estadio }}</h6>
            </div>
          </div>
          <div class="cim-team">
            <img :src="cal.clubVisitaLogo" alt="logo" class="logo-cont" />
            <!--<h6>{{ opnameMin(cal.clubVisita) }}</h6>-->
            <h6>{{ opnameMin(cal.clubVisita) }}</h6>
          </div>
        </div>

        <div class="match-mo" v-else>
          <div class="cim-team">
            <img :src="cal.clubLocalLogo" alt="logo" class="logo-cont" />
            <h6>{{ opnameMin(cal.clubLocal) }}</h6>
          </div>
          <div class="match-stats">
            <img v-if="cal.torneo == 'Clausura'" src="../assets/logoligaexpansion.png" alt="tournament"
              class="tou-logo" />
            <!-- <div class="journey-stadium-cont">
         <img v-if="cal.torneo == 'Clausura' && calendardata.name_tournament[i] == 'Clausura'" 
          src="../assets/grita-mx.png" alt="tournament" class="tou-logo" />-->
            <div class="data-match">
              <h4 v-if="cal.fecha == null">Fecha no disponible</h4>
              <h4 v-else>{{ date2(cal.fecha) }}</h4>
              <h6 v-if="cal.horaLocal == '00:00:00'">N/A</h6>
              <h6 v-else>
                {{ horarioFormat(cal.horaLocal) }} hrs <br />
                (hora local)
              </h6>
            </div>
            <h4 class="match-result-vs" v-if="cal.fecha < today()">
              <span>{{ cal.golLocal }}</span> - <span>{{ cal.golVisita }}</span>
            </h4>
            <div class="journey-stadium-cont">
              <h4>{{ cal.jornada }}</h4>
              <h6>{{ cal.estadio }}</h6>
            </div>
          </div>
          <div class="cim-team">
            <img :src="cal.clubVisitaLogo" alt="logo" class="logo-cont" />
            <h6>CIM</h6>
          </div>
        </div>

        <div class="button-match-cont">
          <div v-if="cal.fecha >= today()">
            <a v-if="cal.clubLocal === 'Cimarrones de Sonora FC'" :href="boletos(cal.fecha)" target="_blank"
              rel="noopener noreferrer">
              <div class="l-button bol">COMPRAR BOLETOS</div>
            </a>
            <a v-else :href="boletos(cal.fecha)" target="_blank" rel="noopener noreferrer" class="nega">
              <div class="l-button bol nega">COMPRAR BOLETOS</div>
            </a>
          </div>
          <a v-else-if="cal.fecha == null" :href="boletos(cal.fecha)" target="_blank" rel="noopener noreferrer"
            class="nega">
            <div class="l-button bol nega">COMPRAR BOLETOS</div>
          </a>
          <!--<a
            v-else-if="this.$route.params.name = 'boletos'"
            href="https://boletomovil.com/cimarrones-sonora"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="l-button bol">BOLETOS</div>
          </a>
          
           <a
            :href="'/calendario/' + idJornada(cal.fecha, cal.jornada, calendardata)"
            v-else-if="(cal.fecha < today()) & (cal.idJornada < 5)"
          >
            <div class="l-button">VER RESULTADOS</div>
          </a>         
          
          -->
          <a :href="'/calendario/' + idJornada(cal.descrp, cal.jornada, calendardata)
            " @click="click(torneoSe, divisionSe, temporadaSe, cal.idJornada)"
            v-else-if="(cal.fecha < today()) & (cal.idJornada < 5)">
            <div class="l-button">VER RESULTADOS</div>
          </a>
          <a :href="'/calendario/' + idJornada(cal.descrp, cal.jornada, calendardata)
            " @click="click(torneoSe, divisionSe, temporadaSe, cal.idJornada)" v-else>
            <div class="l-button">VER RESULTADOS</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default {
  data() {
    return {
      primero: 0,
      ligaexp: [{ division: 20, torneo: 2, temporada: 74, temp: '2023/2024', nom: 'Clausura 24' }],
      // A24: [{ division: 20, torneo: 1, temporada: 75, temp: '2024/2025', nom: 'Apertura 24' }],
      C24: [{ division: 20, torneo: 2, temporada: 74, temp: '2023/2024', nom: 'Clausura 24' }],
      A23: [
        {
          division: 20, torneo: 1, temporada: 74, temp: '2023/2024', nom: 'Apertura 23'
        },
      ],
      C23: [
        {
          division: 20,
          torneo: 2,
          temporada: 73,
          temp: "2022/2023",
          nom: "Clausura 23",
        },
      ],
      A22: [
        {
          division: 20,
          torneo: 1,
          temporada: 73,
          temp: "2022/2023",
          nom: "Apertura 22",
        },
      ],
      C22: [
        {
          division: 2,
          torneo: 2,
          temporada: 72,
          temp: "2021/2022",
          nom: "Clausura 22",
        },
      ],
      A21: [
        {
          division: 2,
          torneo: 1,
          temporada: 72,
          temp: "2021/2022",
          nom: "Apertura 21",
        },
      ],
      C21: [
        {
          division: 2,
          torneo: 2,
          temporada: 71,
          temp: "2020/2021",
          nom: "Clausura 21",
        },
      ],
      A20: [
        {
          division: 2,
          torneo: 1,
          temporada: 71,
          temp: "2020/2021",
          nom: "Apertura 20",
        },
      ],
      ligaexpU: [],
      datafachera: [{}],
      divisionSe: 0,
      temporadaSe: 0,
      torneoSe: 0,
      divisionUSe: 0,
      temporadaUSe: 0,
      torneoUSe: 0,
      textonom: "",
    };
  },
  updated() {
    if (this.ligaexp != this.ligaexpU) {
      this.ligaexpU = this.ligaexp;
      let payload4 = {
        idTorneo: this.torneoSe,
        idDivision: this.divisionSe,
        idTemporada: this.temporadaSe,
        idClub: 10721,
      };
      this.getPartidosClub2(payload4);
    }
  },
  created() {
    this.getCalendar1();
    this.getTournaments2();

    let payload2 = {
      idTorneo: 1,
      idDivision: 3,
    };
    this.getJornadas(payload2);

    let payload3 = {
      idTorneo: 1,
      idDivision: 20,
      idJornada: 16,
    };
    this.getPartidos(payload3);

    let payload4 = {
      idTorneo: this.torneoSe,
      idDivision: this.divisionSe,
      idTemporada: this.temporadaSe,
      idClub: 10721,
    };

    this.getPartidosClub2(payload4);
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    calendardata() {
      this.datafachera = this.$store.getters["calendar/data1"];
      return this.$store.getters["calendar/data1"];
    },
    jornadas() {
      return this.$store.getters["jornadas/data"];
    },
    partidos() {
      return this.$store.getters["partidos/data"];
    },
    torneos() {
      return this.$store.getters["tournament/data2"];
    },
    partidosClub() {
      /**idJornada 18 */
      let partidosAdmin = this.$store.getters["partidos/data4"];
      let my_array = [18, 19, 20, 21, 22, 23, 24, 25];
      let arr = partidosAdmin.filter((ob) => !my_array.includes(ob.idJornada));
      return arr;
    },
    partidosClub1() {
      let partidosAdmin = this.$store.getters["calendar/data1"];
      let arr = partidosAdmin.filter(
        (ob) => ob.name_tournament == this.textonom
      );
      let arr1 = arr.filter((ob) => ob.id_liga == 1);
      return arr.reverse();
    },
    ligaexp(newligaexp) {
      localStorage.ligaexp = JSON.stringify(newligaexp);
      return localStorage.ligaexp;
    },
    divisionMx() {
      let temp = this.ligaexp[0].division;
      this.divisionSe = temp;
      this.divisionUSe = temp;
      return temp;
    },
    /*async divisionMx() {
      let temp = this.ligaexp[0].division;
      this.divisionSe = temp;
      this.divisionUSe = temp;
      return temp;
    },*/
    temporadaMx() {
      let temp = this.ligaexp[0].temporada;
      this.temporadaSe = temp;
      this.temporadaUSe = temp;
      return temp;
    },
    torneoMx() {
      let temp = this.ligaexp[0].torneo;
      this.torneoSe = temp;
      this.torneoUSe = temp;
      return temp;
    },
    nomtext() {
      let temp = this.ligaexp[0].nom;
      this.textonom = temp;
      return temp;
    },
  },
  mounted() {
    if (localStorage.ligaexp) {
      this.ligaexp = JSON.parse(localStorage.getItem("ligaexp"));
    }
  },
  watch: {
    async ligaexp(newligaexp) {
      localStorage.ligaexp = JSON.stringify(newligaexp);
      let data = localStorage.ligaexp;
      this.ligaexpU = data;
      await data;
    },
    async temporadaMx() {
      let temp = this.ligaexp[0].temporada;
      this.temporadaSe = temp;
      this.temporadaUSe = temp;
      await temp;
    },
    async torneoMx() {
      let tor = this.ligaexp[0].torneo;
      this.torneoSe = tor;
      this.torneoUSe = tor;
      await tor;
    },
    async nomtext() {
      let temp = this.ligaexp[0].nom;
      this.textonom = temp;
      await temp;
    },
  },
  methods: {
    ...mapActions("calendar", ["getCalendar1"]),
    ...mapActions("jornadas", ["getJornadas"]),
    ...mapActions("partidos", ["getPartidos"]),
    ...mapActions("tournament", ["getTournaments2"]),
    ...mapActions("partidos", ["getPartidosClub2"]),

    click(torneo, div, tem, jor) {
      let jorn = "";
      let a = jor.split(' ');
      let num = a[1];
      jorn = parseInt(num);
      if (div == 0) {
        div = 20
      }
      let arr = [{ 'numtor': torneo, 'numdiv': div, 'numtem': tem, 'numjor': jorn }];
      let arr2 = JSON.stringify(arr);
      localStorage.setItem("arraym", arr2);
    },
    boletos(fecha) {
      if (fecha == "2022-02-13") {
        find = true;
        return "https://boletomovil.com/evento/paquete-cimarrones-vs-leones-negros-y-alebriges-22-02-12";
      } else if (fecha == "2022-02-16") {
        find = true;
        return "https://boletomovil.com/evento/paquete-cimarrones-vs-leones-negros-y-alebriges-22-02-12";
      } else {
        find = true;
        return "https://boletomovil.com/cimarrones-sonora";
      }
    },
    idJornada(fecha, jornada, torneoApi) {
      let find = false;
      for (let i = 0; i < torneoApi.length; i++) {
        if (find == false) {
          if (
            torneoApi[i].typegame == jornada &&
            torneoApi[i].descrp ==
            fecha /* && torneoApi[i].tempoarada == temporada*/
          ) {
            find = true;
            return torneoApi[i].id;
          }
        }
      }
    },

    exa() {
      let xd = new Date();
      let fecha =
        xd.getFullYear() + "-" + xd.getDate() + "-" + (xd.getMonth() + 1);
      return fecha;
    },

    today() {
      var now = dayjs();
      return now.format("YYYY-MM-DD");
    },

    dataTransform: function (descrp) {
      let fecha = descrp.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },

    matchday: function (descrp) {
      let fecha = descrp.split("-");
      let dd = fecha[4];
      return (fecha = dd);
    },
    matchmonth: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let match = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MMM-YYYY");
      let fecha = match.split("-");
      let mm = fecha[1];
      return (fecha = mm);
    },

    horarioFormat: function (hora) {
      let gazir = hora.split(":");
      let h = gazir[0];
      let m = gazir[1];
      return h + ":" + m;
    },

    date1: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let fa = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MM-YYYY");
      let fecha = fa.split("-");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },

    date2: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let fa = dayjs(descrp, "YYYY-MM-DD").locale(es).format("YYYY-MMM-DD");
      let fecha = fa.split("-");
      let mm = fecha[1];
      let dd = fecha[2];
      let fa2 = dayjs(descrp, "YYYY-MM-DD").locale(es).format("YYYY-MMM-ddd");
      let fecha2 = fa2.split("-");
      let dd2 = fecha2[2];
      return (fecha = dd2 + " " + dd + " " + mm);
    },

    opnameMin: function (opname) {
      if (opname == "Tapatío") {
        return "TAP";
      } else if (opname == "Alebrijes de Oaxaca") {
        return "OAX";
      } else if (opname == "Atlante") {
        return "ATL";
      } else if (opname == "Dorados") {
        return "DOR";
      } else if (opname == "Venados F.C.") {
        return "VEN";
      } else if (opname == "Club Atlético Morelia") {
        return "MOR";
      } else if (opname == "RAYA2 EXPANSIÓN ") {
        return "RAY";
      } else if (opname == "TM Futbol Club") {
        return "TAM";
      } else if (opname == "Pumas Tabasco") {
        return "PUM";
      } else if (opname == "Club Celaya") {
        return "CEL";
      } else if (opname == "Tepatitlán FC") {
        return "TEP";
      } else if (opname == "Tlaxcala F.C.") {
        return "TLX";
      } else if (opname == "Cancún FC") {
        return "CAN";
      } else if (opname == "Mineros de Zacatecas") {
        return "MIN";
      } else if (opname == "Leones Negros") {
        return "UDG";
      } else if (opname == "Correcaminos") {
        return "UAT";
      }
    },

    /*simpledName(texto) {
     texto = texto.replaceAll('.','')
     let text1 = texto.toString();
      let simpled;
      let varo = text1.split(' ').length;
      if(varo >= 3){
        let matches = text1.match(/\b(\w)/g);
        simpled = matches.join('');
      }else if (varo == 2){
        let team = text1.split(' ');
        let word1 = team[0].match(/\b(\w)/g);
        let word2 = team[1].substring(0,3)
        simpled = word1+word2
      }else if (varo == 1){
        simpled = text1.substring(0,3)
      }
      return simpled;
    },*/

    getCalendar1: async function () {
      let result = await this.$store.dispatch("calendar/getCalendar1", {
        option: "calendario",
      });
      //console.log(result)
      this.info = result;
      return result;
    },
    getTournaments2: async function () {
      let result = await this.$store.dispatch("tournament/getTournaments2", {
        option: "torneos",
      });
      //console.log(result)
      this.info = result;
      return result;
    },
  },
};
</script>


<style scoped>
#calendario a.nega {
  pointer-events: none;
  cursor: default !important;
}

#calendario a .l-button.bol.bol.nega {
  opacity: 0.5;
}

.select-cont {
  position: relative;
  left: 72vw;
  top: 1vw;
}

.cla-select {
  position: relative;
  z-index: 999;
  border: none;
  width: fit-content;
  font-size: 1.1vw;
  font-family: "Rajdhani";
  color: gray;
  background: white !important;
  cursor: default;
}

.cla-select>ul {
  display: none;
}

.cla-select:hover>ul {
  display: block;
  background-color: #f9f9f9;
  width: 13.719vw;
  position: relative;
  z-index: 9999;
}

.cla-select:hover>ul>li {
  padding: 0.5vw 1.875vw;
  height: 2.396vw;
}

.cla-select:hover>ul>li:hover {
  background-color: rgb(185, 21, 21) !important;
  background-image: url("../assets/droparr.png");
  background-size: 2.865vw;
  background-repeat: no-repeat;
  background-position-x: 9vw;
  background-position-y: center;
}

.cla-select:hover>ul>li:hover>a {
  color: white;
}

.select-cont-t {
  height: 2vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 2.396vw;
  margin-bottom: 2.396vw;
}

.select-cont-t h4 {
  font-size: 1.1vw;
  font-family: "Rajdhani";
  color: #032a4b;
  line-height: 1.1vw;
  margin: 0 !important;
}

.eqt {
  width: 0.7vw;
  margin-left: 0.5vw;
}

@media (max-width: 768px) {
  .cla-select {
    width: 27vw;
    font-size: 3.1vw;
    height: 8vw;
  }

  .select-cont {
    left: 65vw;
  }

  .select-cont-t {
    height: 20vw;
  }

  .select-cont-t h4 {
    font-size: 3.1vw;
    line-height: 4.1vw;
    margin: 0 !important;
  }

  .cla-select:hover>ul {
    display: block;
    background-color: #f9f9f9;
    width: 30.719vw;
    z-index: 999;
    margin-left: -2vw;
  }

  .cla-select:hover>ul>li {
    padding: 0.5vw 1.875vw;
    height: 5.396vw;
    font-size: 3vw;
  }

  .cla-select:hover>ul>li:hover {
    background-color: rgb(185, 21, 21) !important;
    background-image: url("../assets/droparr.png");
    background-size: 6.865vw;
    background-repeat: no-repeat;
    background-position-x: 21vw;
    background-position-y: center;
  }

  .eqt {
    width: 1.8vw;
    margin-left: 0.5vw;
  }
}
</style>
