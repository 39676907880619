<template >
  <div class="calendar-cont">
    <div v-if="backgr !== 'No se encontraron coincidencias'">
      <img :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image" alt="pc" class="pc imgx">
      <img :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image_mv" alt="mo" class="mo imgx">
    </div><!---->
    <div class="noav" v-else>

    </div>
    <div class="calendar-header">
      <img src="../assets/CALENDARIO.png" alt="head" class="cal-t-h">
      <h1>CALENDARIO</h1>
      <h6>Y RESULTADOS</h6>
    </div>
    <Tabs noTouch="true">
      <TabItem id="primer-equipo">
        <template #name>
          <div class="team-type-header" id="tabactiva" v-bind:class="{ activa: isActive }" @click="alturaMaxima()">
            <h4>EXPANSIÓN</h4>
          </div>
        </template>

        <div class="">
          <CalendarFirstTeam />
        </div>
      </TabItem>
      <TabItem>
        <template #name>
          <div class="team-type-header" @click="displayTab()">
            <h4>PREMIER</h4>
          </div>
        </template>

        <CalendarPremier />
      </TabItem>
      <TabItem>
        <template #name>
          <div class="team-type-header" @click="displayTab()">
            <h4>TERCERA (TDP)</h4>
          </div>
        </template>

        <CalendarTDP />
      </TabItem>
    </Tabs>
  </div>
</template>
<script>
import { Tabs, TabItem } from "vue-material-tabs";
import CalendarFirstTeam from "../components/CalendarFirstTeam";
import CalendarPremier from "../components/CalendarPremier";
import CalendarTDP from "../components/CalendarTDP";
import { mapActions } from "vuex";
let urlpath = require('../global/url')
export default {
  components: {
    Tabs,
    TabItem,
    CalendarFirstTeam,
    CalendarPremier,
    CalendarTDP,
  },
  data() {
    return {
      urlpath,
      isActive: true,
      backgr: []
    }
  },
  async created() {
    let payload2 = {
      id: 'Calendario',
      option: "cabeceras/section",
    };
    this.backgr = await this.getCabecerasById(payload2);
  },
  methods: {
    ...mapActions("cabeceras", ["getCabecerasById"]),
    displayTab() {
      document.getElementById('tabactiva').classList.remove('activa');
      document.getElementById('primer-equipo').style.display = 'none!important';
      document.getElementById('primer-equipo').style.maxHeight = 0;
      document.getElementById('primer-equipo').style.maxWidth = 0;
    },
    alturaMaxima() {
      document.getElementById('primer-equipo').style.maxHeight = 'fit-content';
      document.getElementById('primer-equipo').style.maxWidth = 'fit-content';
    },
  }
};



</script>
<style scoped>
#primer-equipo {
  display: flex !important;
}

#calendario .tabla-liga {
  padding: 2vw 0;
  width: 93vw;
}

.team-type-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activa {
  color: white !important;
  background: #B70D1D;
  padding-right: 5vw !important;
  background-size: 2.865vw !important;
  background-position-x: 14.115vw !important;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  background-image: url('../assets/arrow2.png') !important;
}

.activa:hover {
  padding-right: 0;
}

img.pc.imgx {
  display: block;
}

img.mo.imgx {
  display: none;
}

.noav {
  position: absolute;
  top: 0;
  background-color: #032a4b;
  width: 100%;
  height: 33vw;
}

@media (max-width: 768px) {

  img.pc.imgx {
    display: none;
  } 
  img.mo.imgx {
    top: 21vw;
    display: block;
    height: 51.208vw;
  } 
  .noav {
    top: 21vw;
    height: 51.208vw;
  }
}</style>