<template>
    <div id="calendario">
         <div id="headerHome" class="col-sm-12 col-md-12  color-bg-1">
                <HeaderHome :name="user.fullname" /> 
        </div>
        
        <CalendarCont />
        
         <div id="footerSection">
             <Footer />
         </div>
    </div>
</template>
<script>
import Footer from '../components/footer';
import HeaderHome from '../components/HeaderHome';
import CalendarCont from '../components/CalendarCont';

export default {
    components: {
        HeaderHome,
        Footer,
        CalendarCont,
    },
    computed: {
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
    },


  /**titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Calendario - Cimarrones de Sonora';
            }
        },
    }
    
}
</script>